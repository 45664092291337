import moment from 'moment'
import { KIDS_BRAND_TYPE, KIREILIGN_TYPE, FEMALE, MALE, TYPE_EXPORT_EXAMINATION } from '../utils/constants'
import { showErrorModal, convertToFullWidth } from '../utils/helpers'
import { PDFDocument, layoutMultilineText } from 'pdf-lib'
import fontkit from '@pdf-lib/fontkit'
import { get } from 'lodash'

export const printExamination = {
  data () {
    return {
      font: null,
      font_medium: null,
      form: {}
    }
  },
  methods: {
    async printMultipleExamination (data, date) {
      const pdfMerge = await PDFDocument.create()
      const reservations = data?.reservations || []
      const timeUploadTemplate = get(data, 'time_upload_template', {})

      for (let i = 0; i < reservations.length; i++) {
        const item = reservations[i]
        const type = this.getType(item)
        const template = this.getFileTemplate(
          item.brand_type,
          item.first_reservation_id,
          item.id,
          timeUploadTemplate,
          type
        )
        const dataFill = this.buildata(item, type)
        const resultFillData = await this.fillData(dataFill, template)

        if (!resultFillData) {
          return false
        }

        const { pdfDoc } = resultFillData
        const contentPages = await pdfMerge.copyPages(pdfDoc, pdfDoc.getPageIndices())

        for (const page of contentPages) {
          pdfMerge.addPage(page)
        }
      }

      const pdfMergeBytes = await pdfMerge.save()
      this.downloadFilePdfExamination(pdfMergeBytes, `${date}_診察表.pdf`)
    },
    getType (data) {
      if (data.first_reservation_id === data.id || (data.index === 2 && data.sf_pre_contract_simulation)) {
        return TYPE_EXPORT_EXAMINATION.FIRST_EXAMINATION
      }

      return TYPE_EXPORT_EXAMINATION.RE_EXAMINATION
    },
    async createFilePDF (data, type = null) {
      const template = this.getFileTemplate(
        data.brand_type,
        data.first_reservation_id,
        data.id,
        get(data, 'time_upload_template', {}),
        type
      )
      const dataFill = this.buildata(data, type)
      const resultFillData = await this.fillData(dataFill, template)
      if (!resultFillData) {
        return false
      }

      const { pdfBytes } = resultFillData
      this.printFilePdfExamination(pdfBytes)
    },
    async fillData (data, formUrl) {
      try {
        if (!this.font || !this.font_medium) {
          await this.loadFont()
        }

        if (!this.form[formUrl]) {
          this.form[formUrl] = await fetch(formUrl).then(res => res.arrayBuffer())
        }

        const formPdfBytes = this.form[formUrl]
        const pdfDoc = await PDFDocument.load(formPdfBytes)
        pdfDoc.registerFontkit(fontkit)
        const customFont = await pdfDoc.embedFont(this.font)
        const customFontMedium = await pdfDoc.embedFont(this.font_medium)
        const form = pdfDoc.getForm()
        const pdfPage = pdfDoc.getPage(0)
        const brandType = data.brand_type
        delete data.brand_type
        const heightPlusOfType = brandType === KIDS_BRAND_TYPE ? 8 : 16

        Object.keys(data).forEach((element) => {
          if (element === 'gender_male' || element === 'gender_female') {
            const fieldCheckbox = form.getCheckBox(element)
            if (data[element] === 'On') {
              fieldCheckbox.check()
            }
          } else {
            const field = form.getTextField(element)
            if (element === 'clinic_formal_name') {
              this.renderMultiLine(field, data[element], customFontMedium, pdfPage)
            } else if (element === 'consultation_content') {
              this.drawConsultationContentText(field, data[element], customFont, 15, pdfPage)
            } else if (element === 'sf_user_id') {
              this.drawOtherText(
                field,
                convertToFullWidth(data[element]),
                customFontMedium,
                18,
                pdfPage,
                heightPlusOfType
              )
            } else if (element === 'name' || element === 'roma_name') {
              if (brandType === KIDS_BRAND_TYPE) {
                this.drawOtherText(field, data[element], customFont, 22, pdfPage, 8)
              } else {
                this.renderMultiLine(field, data[element], customFont, pdfPage)
              }
            } else {
              this.drawOtherText(field, data[element], customFont, 18, pdfPage, 4)
            }
          }
        })

        form.updateFieldAppearances(customFont)
        form.flatten()
        const pdfBytes = await pdfDoc.save()

        return { pdfBytes, pdfDoc }
      } catch (error) {
        this.$store.commit('set_loading', false)
        let msg = this.$t('new_message.something_went_wrong')

        if (error.message.includes('No PDF header found')) {
          msg = this.$t('objects.reservation.messages.examination_template_not_exist')
        }

        showErrorModal(msg)

        return false
      }
    },
    renderMultiLine (field, text, customFont, pdfPage) {
      let newText = text
      const [widget] = field.acroField.getWidgets()
      const widthDrawText = widget.getRectangle().width
      const heightDrawText = widget.getRectangle().height
      const positionXDrawText = widget.getRectangle().x
      const positionYDrawText = widget.getRectangle().y
      const width = widget.getRectangle().width - 20
      const height = widget.getRectangle().height
      let fontSize = 22
      let finalHeight = 50

      newText = this.fillParagraph(text, customFont, fontSize, width)
      const multiText = layoutMultilineText(newText, {
        alignment: 'left',
        font: customFont,
        fontSize,
        bounds: { x: 0, y: 0 }
      })

      const countLine = multiText.lines.length

      let positionY = positionYDrawText + 13

      if (countLine >= 2) {
        while (finalHeight > height) {
          fontSize--
          newText = this.fillParagraph(text, customFont, fontSize, width)
          const multiText = layoutMultilineText(newText, {
            alignment: 'left',
            font: customFont,
            fontSize,
            bounds: { x: 0, y: 0 }
          })

          finalHeight = multiText.lines[0].height * multiText.lines.length
        }

        positionY = positionYDrawText + heightDrawText - fontSize
      }

      pdfPage.drawText(newText, {
        x: positionXDrawText + 2,
        y: positionY,
        font: customFont,
        size: fontSize,
        width: widthDrawText,
        height: heightDrawText,
        lineHeight: 16
      })
    },
    drawConsultationContentText (field, text, customFont, fontSize, pdfPage) {
      let newText = text
      const [widget] = field.acroField.getWidgets()
      const widthDrawText = widget.getRectangle().width
      const heightDrawText = widget.getRectangle().height
      const positionXDrawText = widget.getRectangle().x
      const positionYDrawText = widget.getRectangle().y
      let finalHeight = 100

      while (finalHeight > heightDrawText) {
        fontSize--
        newText = this.fillParagraph(text, customFont, fontSize, widthDrawText - 10)
        const multiText = layoutMultilineText(newText, {
          alignment: 'left',
          font: customFont,
          fontSize,
          bounds: { x: 0, y: 0 }
        })

        finalHeight = multiText.lines[0].height * multiText.lines.length
      }

      pdfPage.drawText(newText, {
        x: positionXDrawText + 2,
        y: positionYDrawText + heightDrawText - 15,
        font: customFont,
        size: fontSize,
        width: widthDrawText,
        height: heightDrawText,
        lineHeight: 16
      })
    },
    drawOtherText (field, text, customFont, fontSize, pdfPage, heightFlus = 0) {
      const [widget] = field.acroField.getWidgets()
      const widthDrawText = widget.getRectangle().width
      const heightDrawText = widget.getRectangle().height
      const positionXDrawText = widget.getRectangle().x
      const positionYDrawText = widget.getRectangle().y
      let finalHeight = 50

      let newText = this.fillParagraph(text, customFont, fontSize, widthDrawText)
      const multiText = layoutMultilineText(newText, {
        alignment: 'left',
        font: customFont,
        fontSize,
        bounds: { x: 0, y: 0 }
      })

      const countLine = multiText.lines.length

      if (countLine >= 2) {
        while (finalHeight > heightDrawText) {
          fontSize--
          newText = this.fillParagraph(text, customFont, fontSize, widthDrawText)
          const multiText = layoutMultilineText(newText, {
            alignment: 'left',
            font: customFont,
            fontSize,
            bounds: { x: 0, y: 0 }
          })

          finalHeight = multiText.lines[0].height * multiText.lines.length
        }
      }

      pdfPage.drawText(newText, {
        x: positionXDrawText + 2,
        y: positionYDrawText + heightFlus,
        font: customFont,
        size: fontSize,
        width: widthDrawText,
        height: heightDrawText,
        lineHeight: fontSize
      })
    },
    fillParagraph (text, font, fontSize, maxWidth) {
      var paragraphs = text.split('\n')
      for (let index = 0; index < paragraphs.length; index++) {
        var paragraph = paragraphs[index]
        if (font.widthOfTextAtSize(paragraph, fontSize) > maxWidth) {
          var words = paragraph.split('')
          var newParagraph = []
          var i = 0
          newParagraph[i] = []
          for (let k = 0; k < words.length; k++) {
            var word = words[k]
            newParagraph[i].push(word)
            if (font.widthOfTextAtSize(newParagraph[i].join(''), fontSize) > maxWidth) {
              newParagraph[i].splice(-1)
              i = i + 1
              newParagraph[i] = []
              newParagraph[i].push(word)
            }
          }
          paragraphs[index] = newParagraph.map(p => p.join('')).join('\n')
        }
      }
      return paragraphs.join('\n')
    },
    getFileTemplate (brandType, firstReservationId, reservationId, timeUploads, type = null) {
      let path = ''
      let version = moment().unix()
      if (brandType === KIDS_BRAND_TYPE) {
        if (firstReservationId === reservationId || type === TYPE_EXPORT_EXAMINATION.FIRST_EXAMINATION) {
          path = 'template-first-examination-kids.pdf'
          version = get(timeUploads, 'first_examination_kids', version)
        } else {
          path = 'template-re-examination-kids.pdf'
          version = get(timeUploads, 're_examination_kids', version)
        }
      } else {
        if (firstReservationId === reservationId || type === TYPE_EXPORT_EXAMINATION.FIRST_EXAMINATION) {
          path = 'template-first-examination-kireilign.pdf'
          version = get(timeUploads, 'first_examination', version)
        } else {
          path = 'template-re-examination-kireilign.pdf'
          version = get(timeUploads, 're_examination', version)
        }
      }

      return `${process.env.MIX_APP_URL}/api/print-examination/get-template/${path}?version=${version}`
    },
    buildata (data, type = null) {
      const isValidBirthOfDate = moment(data.birth_of_date, 'YYYY-MM-DD', true).isValid()

      let result = {
        clinic_formal_name: data.print_name_1 || '',
        sf_user_id: data.sf_user_id,
        name: data.name,
        roma_name: data.roma_last_name + ' ' + data.roma_first_name,
        gender_male: data.gender === MALE ? 'On' : 'Off',
        gender_female: data.gender === FEMALE ? 'On' : 'Off',
        birth_year: isValidBirthOfDate ? moment(data.birth_of_date).format('YYYY') : '',
        birth_month: isValidBirthOfDate ? moment(data.birth_of_date).format('MM') : '',
        birth_day: isValidBirthOfDate ? moment(data.birth_of_date).format('DD') : '',
        age: isValidBirthOfDate ? moment().diff(data.birth_of_date, 'years').toString() : ''
      }

      const startedTo = data.started_to ? moment(data.started_to) : ''
      const startedTime = {
        reservation_year: startedTo ? startedTo.format('YYYY') : '',
        reservation_month: startedTo ? startedTo.format('MM') : '',
        reservation_day: startedTo ? startedTo.format('DD') : ''
      }

      const consultationOne = data.consultation_content1
        ? data.consultation_content1.replace(/[\n]/g, ' ').slice(0, 120)
        : ''
      const consultationTwo = data.consultation_content2
        ? data.consultation_content2.replace(/[\n]/g, ' ').slice(0, 80)
        : ''
      const consultationThree = data.consultation_content3
        ? data.consultation_content3.replace(/[\n]/g, ' ').slice(0, 40)
        : ''
      const consultationContent = `[1]${consultationOne} [2]${consultationTwo} [3]${consultationThree}`

      if (data.first_reservation_id === data.id || type === TYPE_EXPORT_EXAMINATION.FIRST_EXAMINATION) {
        result.consultation_content = consultationContent
      }

      if (data.brand_type === KIDS_BRAND_TYPE) {
        result = {
          ...result,
          ...startedTime,
          brand_type: KIDS_BRAND_TYPE
        }
      } else {
        result = {
          ...result,
          name: data.name + ' / ' + data.roma_last_name + ' ' + data.roma_first_name,
          brand_type: KIREILIGN_TYPE
        }

        delete result.roma_name
      }

      return result
    },
    downloadFilePdfExamination (pdfBytes, fileName) {
      var fileBlob = new Blob([pdfBytes], { type: 'application/pdf' })
      var dataContent = URL.createObjectURL(fileBlob)
      const link = document.createElement('a')
      link.setAttribute('href', dataContent)
      link.setAttribute('download', fileName)
      link.click()
      link.remove()
      this.$store.commit('set_loading', false)
    },
    printFilePdfExamination (pdfBytes) {
      const fileBlob = new Blob([pdfBytes], { type: 'application/pdf' })
      const blobUrl = URL.createObjectURL(fileBlob)
      const iframe = document.createElement('iframe')
      document.body.appendChild(iframe)
      iframe.style.display = 'none'
      iframe.src = blobUrl

      iframe.onload = () => {
        this.$store.commit('set_loading', false)
        iframe.contentWindow.print()
      }
    },
    async loadFont () {
      const fontUrl = process.env.MIX_APP_URL + '/fonts/ZenKakuGothicAntique-Regular.ttf'
      this.font = await fetch(fontUrl).then((res) => res.arrayBuffer())
      const fontMediumUrl = process.env.MIX_APP_URL + '/fonts/ZenKakuGothicAntique-Medium.ttf'
      this.font_medium = await fetch(fontMediumUrl).then((res) => res.arrayBuffer())
    }
  }
}
