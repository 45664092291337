export default {
  button: {
    change_order_finish_to_shipped: 'Return the checked order to shipping completion',
    confirm: 'Order confirmation',
    create_instructions: 'Create instructions by WEB input',
    download_setup_data: 'Download setup data',
    open_simulation: 'Open simulation',
    patient_information: 'Patient information',
    print: 'Print',
    print_1: 'Print order details',
    print_instruction: 'Print labor instructions',
    print_order_detail: 'Print order detail',
    update_latest_information: 'Update to the latest information',
    upload: 'Upload'
  },
  message: {
    cannot_print_because_exist_error_files: 'The technician\'s instructions have not been uploaded. Some order information cannot be printed.\n' +
      'Please check the order information below\n' +
      '{orderIds}',
    cannot_print_because_no_image: 'Cannot print because there is no image',
    not_found_data_setup: 'Setup data can only be downloaded when ordering aligners.\n' +
      'If you cannot download the aligners despite ordering them, please contact the laboratory.',
    patient_does_not_have_any_reserved_reservation: 'To place an order, you must register for an initial consultation appointment. Please register your initial appointment from the appointment calendar before placing your order.',
    simulation_url_is_not_set: 'The simulation URL has not been set.'
  },
  placeholder: {
    count: 'Search by number of times',
    g_number: 'Search by g number',
    order_id: 'Search by order ID',
    visit_number: 'Please select the number of order stages'
  },
  text: {
    status: {
      0: 'No reservation',
      1: 'Clinic confirmation',
      2: 'Checking before ordering',
      3: 'During setup design',
      4: 'Preparing to ship',
      5: 'Shipment completed',
      6: 'Cancelled',
      7: 'Delivery completed'
    },
    about_expansion: 'About expansion floor use',
    about_oral_data: 'About oral data',
    aligner_shipping_date: 'Aligner shipping date',
    amount_of_money: 'Amount of money',
    asc_all: 'All',
    asc_excluding: 'Excluding ACS',
    asc_only: 'ACS only',
    checked_order: 'Complete delivery of checked orders',
    clean_line: 'Current order stage number',
    clinic_name: 'Clinic name',
    close_advanced_search: 'Close advanced search',
    confirm_update_status_msg: 'Change the status of the checked order information to\nDelivery completed',
    confirm_update_status_finish_msg: 'Check the status of the ordered information\nChange to shipping completion',
    consultation_day: 'Consultation day',
    contact_laboratory: 'Contact from the laboratory',
    count: 'Count',
    course_name: 'Course name',
    create_order_confirm: 'Do you want to place an order?',
    create_order_success: 'The patient ID {sf_user_id} has been ordered',
    date_shipment: 'Date of shipment',
    delivery_completion_date: 'Delivery completion date',
    delivery_date: 'Desired delivery date',
    doctor_in_charge: 'Doctor in charge',
    download_csv: 'Download search results in CSV',
    drag_limit_title: 'Drag or select the file you want to upload',
    drag_limit_title_sp: 'Please upload the file.',
    drag_limit_size: '(upper size limit of {limit}MB per file)',
    drag_limit_size_with_stl: '(upper size limit of {limit}MB per file ※STL is {limit_stl}MB)',
    estimated_shipping_date: 'Estimated shipping date',
    file_upload: 'Face photo, intraoral photo, X-ray upload',
    file_upload_additional_note: 'Additionally, please upload these images for comparison before and after treatment when placing the initial order for retainers (i.e., at the end of dynamic treatment).',
    file_upload_help: 'Please make sure to upload the following items when placing your initial order. (We may not be able to reflect information on the prosthetic device if uploads are missing or the data does not meet specifications.)',
    file_upload_help_link: 'How to take pictures?',
    file_upload_note_1: '・3 facial photographs (neutral expression, smiling, profile view with lips closed)',
    file_upload_note_2: '・5 intraoral photographs (frontal, left and right lateral views, upper and lower occlusal views)',
    file_upload_note_3: '・Panoramic radiograph data (showing the entire dentition)',
    file_uploaded: 'Uploaded file',
    // text for file upload additional
    file_upload_additional: 'Additional Face photo, intraoral photo, X-ray upload',
    file_upload_additional_help_link: 'How to take pictures?',
    file_upload_additional_note_1: 'Please re-upload from here only if additional documents are requested by SheepMedical order staff.',
    file_upload_additional_note_2: 'Please notify us by email once the re-upload is complete.',
    file_upload_additional_note_3: 'Please note that technical instructions cannot be re-uploaded, so if there is an error in the technical instructions, please place a new order.',
    file_uploaded_additional: 'Additional files to upload',
    file_uploaded_additional_success: 'Additional file upload completed',
    first_order: {
      0: 'Not order',
      1: 'Order'
    },
    footer_content: 'For laboratory information, please check the Kirekar Support Site\n' +
      '<a target="_blank" href="https://support.kirekar.com/faq/page-2208/">' +
      'https://support.kirekar.com/faq/page-2208/</a>',
    footer_title: 'Please contact the clinic support staff for information on how to operate Kirekal and its rules.',
    for_set: 'For the set',
    g_number: 'g number',
    ipr_amount: 'IPR amount',
    list_display: 'List display',
    number_order_image: '{number}{suffix} order image',
    number_orders: 'Order history (total {number_order} times)',
    number_treatment: 'Total number of treatments {number}',
    order_contract_simulation: 'Order',
    order_creation_notification: '* We recommend WEB input so that there is no discrepancy in the exchange of orders. Thank you for your cooperation.',
    order_date: 'Order date',
    order_detail: 'Order detail',
    order_details: 'Order details',
    order_id: 'Order ID',
    order_mouthpiece: 'This order mouthpiece',
    order_received_date: 'Order received date',
    order_search_notification: 'Please specify the search conditions and search',
    order_visit_number_note: 'Please select simulation, expanded floor, remanufacturing, or other',
    order_visit_number_note_other: {
      1: 'Other：',
      2: 'Simulation, expansion floor, reproduction, retainer, etc.'
    },
    order_visit_number_option: {
      0: 'Other',
      1: '1 Stage',
      3: '3 Stages ([Old])',
      5: '5 Stages',
      6: '6 Stages ([Old] additional batch)',
      9: '9 Stages ([Old] additional batch)',
      10: '10 Stages',
      15: '15 Stages',
      kids: 'KIDS'
    },
    orthodontic_phase: 'Orthodontic phase',
    patient_id: 'Patient ID',
    patient_name: 'Full name',
    patient_romaji_name: 'Romaji name',
    person_in_charge: 'Doctor in charge',
    please_select_file: '※ Please select file',
    please_select_file_with_simulation: '※ For the first order after the contract of the pre-contract simulation implementer, please upload the first examination table.',
    pre_contract_simulation: 'Pre-contract simulation',
    presscut_complete_date: 'Press cut compete date',
    presscut_complete_date_label: 'Press cut compete date',
    print_checked_technician: 'Print the checked technician instruction table at once',
    print_instruction_status: 'Instructions',
    print_status: 'Technician Instructions',
    product_time: '{name} {number}{suffix} time',
    scan_data: 'Defective scan data (charges are available for remanufacturing)',
    select_files: 'Select files',
    setup_press_cut: 'Setup press cut',
    setup_staff: 'Setup staff',
    sf_exvolume_1l: '1st Stage enlargement amount below {value} mm',
    sf_exvolume_1u: '1st Stage enlargement amount above {value} mm',
    sf_exvolume_2l: '2nd Stage enlargement amount below {value} mm',
    sf_exvolume_2u: '2nd Stage enlargement amount above {value} mm',
    sf_exvolume_3l: '3rd stage enlargement amount below {value} mm',
    sf_exvolume_3u: '3rd Stage enlargement amount above {value} mm',
    sf_exvolume_4l: '4th Stage enlargement amount below {value} mm',
    sf_exvolume_4u: '4th Stage enlargement amount above {value} mm',
    sf_exvolume_5l: '5th Stage enlargement amount below {value} mm',
    sf_exvolume_5u: '5th Stage enlargement amount above {value} mm',
    sf_pre_contract_simulation: {
      0: '- Not implemented',
      1: '◯ Implemented'
    },
    stl_file_upload_title: 'Upload Intraoral Scan',
    stl_file_upload_note_1: 'Data Format: One file per arch',
    stl_file_upload_note_2: 'File Type: STL Data',
    stl_file_upload_additional_note: 'No upload is required if sending the scan to the lab via plaster model or cloud',
    stl_file_uploaded: 'Intraoral Scan Uploaded',
    technician_instructions: 'Technician instructions',
    this_order: 'This order',
    title_create_order: 'Order',
    unprinted: 'Unprinted',
    upload_technician_instructions: 'Technician instruction upload',
    upload_technician_instructions_help: 'If sections are left blank, items are not checked, or if text recognition is difficult and the content cannot be confirmed, re-uploading may be necessary.\n' +
      '(Please be aware that this may lead to delays in delivery due to the time required for confirmation.)',
    upload_technician_instructions_help_link: 'How to write a technical?',
    upload_technician_instructions_note_1: '・Technical instruction for the first visit: pre-contract simulation /',
    upload_technician_instructions_note_1_1: '1st stage aligner',
    upload_technician_instructions_note_2: '・Technical instruction for re-examination: other than the above',
    uploaded_technician_instructions: 'Uploaded technician instructions table',
    worker_setup: 'Setup staff',
    worker_presscut: 'Press cut staff'
  }
}
