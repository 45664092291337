var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "patient-v2 patient-v2--normal" },
    [
      _c("patient-header-normal", {
        attrs: { "custom-title": _vm.$t("objects.patient.text.title_editing") },
        on: { return: _vm.handleReturnAction, submit: _vm.handleSubmitAction }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "patient-v2__body" }, [
        _c("div", { staticClass: "patient-v2__content" }, [
          _c("div", { staticClass: "patient-v2__row" }, [
            _c("div", { staticClass: "col-12 patient-v2__item" }, [
              _c("h2", { staticClass: "patient-v2__row__title" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("objects.patient.text.basic_info")) +
                    "\n          "
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "patient-v2__row__group" }, [
              _c(
                "div",
                { staticClass: "col-md-6 patient-v2__item" },
                [
                  _c("label", { attrs: { for: "last_name" } }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.$t("new_text.last_name")) +
                        "\n            "
                    )
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "required" }, [
                    _vm._v(_vm._s(_vm.$t("new_text.required")))
                  ]),
                  _vm._v(" "),
                  _c("input-common", {
                    class: [
                      "patient-v2__input",
                      { "patient-v2__input--error": _vm.errors.last_name }
                    ],
                    attrs: {
                      id: "last_name",
                      "default-value": _vm.patient_form.last_name,
                      name: "last_name",
                      placeholder: _vm.$t("new_validation.common.required", {
                        attribute: _vm.$t("new_text.last_name")
                      }),
                      type: "text",
                      maxlength: "80"
                    },
                    on: { input: _vm.updateFuriganaLast },
                    model: {
                      value: _vm.patient_form.last_name,
                      callback: function($$v) {
                        _vm.$set(_vm.patient_form, "last_name", $$v)
                      },
                      expression: "patient_form.last_name"
                    }
                  }),
                  _vm._v(" "),
                  _vm.errors.last_name
                    ? _c("span", { staticClass: "error" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.errors.last_name) +
                            "\n            "
                        )
                      ])
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-md-6 patient-v2__item" },
                [
                  _c("label", { attrs: { for: "first_name" } }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.$t("new_text.first_name")) +
                        "\n            "
                    )
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "required" }, [
                    _vm._v(_vm._s(_vm.$t("new_text.required")))
                  ]),
                  _vm._v(" "),
                  _c("input-common", {
                    class: [
                      "patient-v2__input",
                      { "patient-v2__input--error": _vm.errors.first_name }
                    ],
                    attrs: {
                      id: "first_name",
                      "default-value": _vm.patient_form.first_name,
                      name: "first_name",
                      placeholder: _vm.$t("new_validation.common.required", {
                        attribute: _vm.$t("new_text.first_name")
                      }),
                      type: "text",
                      maxlength: "40"
                    },
                    on: { input: _vm.updateFuriganaFirst },
                    model: {
                      value: _vm.patient_form.first_name,
                      callback: function($$v) {
                        _vm.$set(_vm.patient_form, "first_name", $$v)
                      },
                      expression: "patient_form.first_name"
                    }
                  }),
                  _vm._v(" "),
                  _vm.errors.first_name
                    ? _c("span", { staticClass: "error" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.errors.first_name) +
                            "\n            "
                        )
                      ])
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-md-6 patient-v2__item" },
                [
                  _c("label", { attrs: { for: "kana_last_name" } }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.$t("new_text.kata_last_name")) +
                        "\n            "
                    )
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "required" }, [
                    _vm._v(_vm._s(_vm.$t("new_text.required")))
                  ]),
                  _vm._v(" "),
                  _c("input-common", {
                    class: [
                      "patient-v2__input",
                      { "patient-v2__input--error": _vm.errors.kana_last_name }
                    ],
                    attrs: {
                      id: "kana_last_name",
                      "default-value": _vm.patient_form.kana_last_name,
                      name: "kana_last_name",
                      placeholder: _vm.$t("new_validation.common.required", {
                        attribute: _vm.$t("new_text.kata_last_name")
                      }),
                      type: "text",
                      maxlength: "191"
                    },
                    on: { input: _vm.updateKanaLast },
                    model: {
                      value: _vm.patient_form.kana_last_name,
                      callback: function($$v) {
                        _vm.$set(_vm.patient_form, "kana_last_name", $$v)
                      },
                      expression: "patient_form.kana_last_name"
                    }
                  }),
                  _vm._v(" "),
                  _vm.errors.kana_last_name
                    ? _c("span", { staticClass: "error" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.errors.kana_last_name) +
                            "\n            "
                        )
                      ])
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-md-6 patient-v2__item" },
                [
                  _c("label", { attrs: { for: "kana_first_name" } }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.$t("new_text.kata_first_name")) +
                        "\n            "
                    )
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "required" }, [
                    _vm._v(_vm._s(_vm.$t("new_text.required")))
                  ]),
                  _vm._v(" "),
                  _c("input-common", {
                    class: [
                      "patient-v2__input",
                      { "patient-v2__input--error": _vm.errors.kana_first_name }
                    ],
                    attrs: {
                      id: "kana_first_name",
                      "default-value": _vm.patient_form.kana_first_name,
                      name: "kana_first_name",
                      placeholder: _vm.$t("new_validation.common.required", {
                        attribute: _vm.$t("new_text.kata_first_name")
                      }),
                      type: "text",
                      maxlength: "191"
                    },
                    on: { input: _vm.updateKanaFirst },
                    model: {
                      value: _vm.patient_form.kana_first_name,
                      callback: function($$v) {
                        _vm.$set(_vm.patient_form, "kana_first_name", $$v)
                      },
                      expression: "patient_form.kana_first_name"
                    }
                  }),
                  _vm._v(" "),
                  _vm.errors.kana_first_name
                    ? _c("span", { staticClass: "error" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.errors.kana_first_name) +
                            "\n            "
                        )
                      ])
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-md-6 patient-v2__item" },
                [
                  _c("label", { attrs: { for: "roma_last_name" } }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.$t("new_text.roma_last_name")) +
                        "\n            "
                    )
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "required" }, [
                    _vm._v(_vm._s(_vm.$t("new_text.required")))
                  ]),
                  _vm._v(" "),
                  _c("input-common", {
                    staticClass: "patient-v2__input",
                    attrs: {
                      id: "roma_last_name",
                      "default-value": _vm.patient_form.roma_last_name,
                      name: "roma_last_name",
                      placeholder: _vm.$t("new_validation.common.required", {
                        attribute: _vm.$t("new_text.roma_last_name")
                      }),
                      type: "text",
                      maxlength: "191"
                    },
                    on: {
                      input: function($event) {
                        _vm.errors.roma_last_name = ""
                      }
                    },
                    model: {
                      value: _vm.patient_form.roma_last_name,
                      callback: function($$v) {
                        _vm.$set(_vm.patient_form, "roma_last_name", $$v)
                      },
                      expression: "patient_form.roma_last_name"
                    }
                  }),
                  _vm._v(" "),
                  _vm.errors.roma_last_name
                    ? _c("span", { staticClass: "error" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.errors.roma_last_name) +
                            "\n            "
                        )
                      ])
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-md-6 patient-v2__item" },
                [
                  _c("label", { attrs: { for: "roma_first_name" } }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.$t("new_text.roma_first_name")) +
                        "\n            "
                    )
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "required" }, [
                    _vm._v(_vm._s(_vm.$t("new_text.required")))
                  ]),
                  _vm._v(" "),
                  _c("input-common", {
                    staticClass: "patient-v2__input",
                    attrs: {
                      id: "roma_first_name",
                      "default-value": _vm.patient_form.roma_first_name,
                      name: "roma_first_name",
                      placeholder: _vm.$t("new_validation.common.required", {
                        attribute: _vm.$t("new_text.roma_first_name")
                      }),
                      type: "text",
                      maxlength: "191"
                    },
                    on: {
                      input: function($event) {
                        _vm.errors.roma_first_name = ""
                      }
                    },
                    model: {
                      value: _vm.patient_form.roma_first_name,
                      callback: function($$v) {
                        _vm.$set(_vm.patient_form, "roma_first_name", $$v)
                      },
                      expression: "patient_form.roma_first_name"
                    }
                  }),
                  _vm._v(" "),
                  _vm.errors.roma_first_name
                    ? _c("span", { staticClass: "error" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.errors.roma_first_name) +
                            "\n            "
                        )
                      ])
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-6 patient-v2__item" }, [
                _c("label", [_vm._v(_vm._s(_vm.$t("new_text.sex")))]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "patient-v2__sex" },
                  [
                    _c("radio-common", {
                      staticClass: "patient-v2__sex__input",
                      attrs: {
                        name: "sex",
                        label: _vm.$t("new_text.placeholder.no_selection"),
                        "is-checked":
                          _vm.patient_form.gender !== 1 &&
                          _vm.patient_form.gender !== 2
                      },
                      on: { select: _vm.getDataGender }
                    }),
                    _vm._v(" "),
                    _c("radio-common", {
                      staticClass: "patient-v2__sex__input",
                      attrs: {
                        name: "sex",
                        label: _vm.$t("new_text.male"),
                        "is-checked": _vm.patient_form.gender === 2,
                        val: 2
                      },
                      on: { select: _vm.getDataGender }
                    }),
                    _vm._v(" "),
                    _c("radio-common", {
                      staticClass: "patient-v2__sex__input",
                      attrs: {
                        name: "sex",
                        label: _vm.$t("new_text.female"),
                        val: 1,
                        "is-checked": _vm.patient_form.gender === 1
                      },
                      on: { select: _vm.getDataGender }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-6 patient-v2__item" }, [
                _c("label", [_vm._v(_vm._s(_vm.$t("new_text.birthday")))]),
                _vm._v(" "),
                _c("div", { staticClass: "patient-v2__birthday" }, [
                  _c(
                    "span",
                    { staticClass: "patient-v2__birthday__group" },
                    [
                      _c("select-common", {
                        attrs: {
                          options: _vm.listYear,
                          name: "birthdate_year",
                          "default-value": _vm.patient_form.birthdate_year,
                          "key-value": "value",
                          "key-display": "text"
                        },
                        on: { change: _vm.getLeapYear }
                      }),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(_vm.$t("new_text.year")))])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    { staticClass: "patient-v2__birthday__group" },
                    [
                      _c("select-common", {
                        attrs: {
                          options: _vm.listMonth,
                          name: "birthdate_month",
                          "default-value": _vm.patient_form.birthdate_month,
                          "key-value": "value",
                          "key-display": "text"
                        },
                        on: {
                          change: function(value) {
                            return (_vm.patient_form.birthdate_month = value)
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(_vm.$t("new_text.month")))])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    { staticClass: "patient-v2__birthday__group" },
                    [
                      _c("select-common", {
                        attrs: {
                          options: _vm.listDay,
                          name: "birthdate_day",
                          "default-value": _vm.patient_form.birthdate_day,
                          "key-value": "value",
                          "key-display": "text"
                        },
                        on: {
                          change: function(value) {
                            return (_vm.patient_form.birthdate_day = value)
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(_vm.$t("new_text.day")))])
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _vm.errors.birthday
                  ? _c("span", { staticClass: "error" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.errors.birthday) +
                          "\n            "
                      )
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-6 patient-v2__item" }, [
                _c("label", [_vm._v(_vm._s(_vm.$t("new_text.brand_type")))]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "patient-v2__brand" },
                  [
                    _c("select-common", {
                      attrs: {
                        placeholder: _vm.$t(
                          "new_text.placeholder.please_select"
                        ),
                        options: _vm.brand_masters,
                        name: "brand_type",
                        "key-value": "type",
                        disabled: _vm.disabledBrandType,
                        "default-value": _vm.patient_form.brand_type
                      },
                      on: { change: _vm.setBrandType }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-6 patient-v2__item" }, [
                _c("label", [
                  _vm._v(
                    _vm._s(_vm.$t("objects.patient.text.treatmentend_status"))
                  )
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "patient-v2__treatmentend-status" },
                  [
                    _c("select-common", {
                      attrs: {
                        options: _vm.treatmentendStatus,
                        name: "sf_treatmentend_status_c",
                        "key-value": "value",
                        "key-display": "label",
                        "default-value":
                          _vm.patient_form.sf_treatmentend_status_c
                      },
                      on: {
                        change: function(value) {
                          return (_vm.patient_form.sf_treatmentend_status_c = value)
                        }
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _vm.isDisplayedReasonForNonContract
                ? _c(
                    "div",
                    {
                      staticClass:
                        "col-md-12 patient-v2__item patient-v2__item--reason-for-non-contract"
                    },
                    [
                      _c("label", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "objects.patient.text.reason_for_non_contracting"
                            )
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "patient-v2__item__description" },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.$t(
                                  "objects.patient.text.reason_for_non_contract_update_description"
                                )
                              )
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "patient-v2__item__bottom" }, [
                        _c(
                          "button",
                          {
                            staticClass:
                              "button-tertiary button-tertiary--small",
                            on: {
                              click: function($event) {
                                return _vm.setReasonForNonContractModalStatus(
                                  true
                                )
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.$t("new_text.button.select")) +
                                "\n              "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass: "ws-pre-wrap patient-v2__item__content"
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.patient_form.reason_for_non_contract)
                            )
                          ]
                        )
                      ])
                    ]
                  )
                : _vm._e()
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "patient-v2__row" }, [
            _c("div", { staticClass: "col-12 patient-v2__item" }, [
              _c("h2", { staticClass: "patient-v2__row__title" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("objects.patient.text.contact_info")) +
                    "\n          "
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "patient-v2__row__group" }, [
              _c(
                "div",
                { staticClass: "col-12 patient-v2__item" },
                [
                  _c("label", { staticClass: "patient-v2__label__zipcode" }, [
                    _vm._v(_vm._s(_vm.$t("new_text.zip_code")))
                  ]),
                  _vm._v(" "),
                  _c("input-common", {
                    class: [
                      "patient-v2__input patient-v2__input__zipcode",
                      { "patient-v2__input--error": _vm.errors.zip_code }
                    ],
                    attrs: {
                      name: "zip_code",
                      type: "text",
                      placeholder: _vm.$t(
                        "new_text.placeholder.no_hyphens_needed"
                      ),
                      maxlength: "7"
                    },
                    on: { input: _vm.handleZipcodeChange },
                    model: {
                      value: _vm.patient_form.zip_code,
                      callback: function($$v) {
                        _vm.$set(_vm.patient_form, "zip_code", $$v)
                      },
                      expression: "patient_form.zip_code"
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "button-v2 patient-v2__action__zipcode",
                      on: { click: _vm.validateZipCode }
                    },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t("new_text.button.address_search")) +
                          "\n            "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _vm.errors.zipcode
                    ? _c(
                        "span",
                        { staticClass: "error patient-v2__error-zipcode" },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.errors.zipcode) +
                              "\n            "
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-12 patient-v2__item" },
                [
                  _c("label", [_vm._v(_vm._s(_vm.$t("new_text.address")))]),
                  _vm._v(" "),
                  _c("input-common", {
                    ref: "address",
                    staticClass:
                      "patient-v2__input patient-v2__input--full-width",
                    attrs: {
                      name: "address",
                      placeholder: _vm.$t("new_validation.common.required", {
                        attribute: _vm.$t("new_text.address")
                      }),
                      type: "text"
                    },
                    model: {
                      value: _vm.patient_form.address,
                      callback: function($$v) {
                        _vm.$set(_vm.patient_form, "address", $$v)
                      },
                      expression: "patient_form.address"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-md-6 patient-v2__item" },
                [
                  _c("label", [
                    _vm._v(_vm._s(_vm.$t("new_text.phone_number")))
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "required" }, [
                    _vm._v(_vm._s(_vm.$t("new_text.required")))
                  ]),
                  _vm._v(" "),
                  _c("input-common", {
                    class: [
                      "patient-v2__input",
                      { "patient-v2__input--error": _vm.errors.tel }
                    ],
                    attrs: {
                      name: "tel",
                      placeholder: _vm.$t("new_validation.common.required", {
                        attribute: _vm.$t("new_text.phone_number")
                      }),
                      type: "text",
                      maxlength: "11"
                    },
                    on: { input: _vm.handlePhoneChange },
                    model: {
                      value: _vm.patient_form.tel,
                      callback: function($$v) {
                        _vm.$set(_vm.patient_form, "tel", $$v)
                      },
                      expression: "patient_form.tel"
                    }
                  }),
                  _vm._v(" "),
                  _vm.errors.tel
                    ? _c("span", { staticClass: "error" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.errors.tel) +
                            "\n            "
                        )
                      ])
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-md-6 patient-v2__item" },
                [
                  _c("label", [
                    _vm._v(
                      _vm._s(
                        _vm.$t("objects.patient.text.sms_delivery_setting")
                      )
                    )
                  ]),
                  _vm._v(" "),
                  _c("checkbox-common", {
                    attrs: {
                      name: "is_receive_sms",
                      "is-checked": _vm.patient_form.is_receive_sms === 1,
                      label: _vm.$t("new_text.receive_sms")
                    },
                    model: {
                      value: _vm.patient_form.is_receive_sms,
                      callback: function($$v) {
                        _vm.$set(_vm.patient_form, "is_receive_sms", $$v)
                      },
                      expression: "patient_form.is_receive_sms"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-md-6 patient-v2__item" },
                [
                  _c("label", [_vm._v(_vm._s(_vm.$t("new_text.email")))]),
                  _vm._v(" "),
                  _c("input-common", {
                    class: [
                      "patient-v2__input",
                      { "patient-v2__input--error": _vm.errors.email }
                    ],
                    attrs: {
                      name: "email",
                      placeholder: _vm.$t("new_validation.common.required", {
                        attribute: _vm.$t("new_text.email")
                      }),
                      type: "text",
                      maxlength: "80"
                    },
                    on: { input: _vm.handleMailChange },
                    model: {
                      value: _vm.patient_form.email,
                      callback: function($$v) {
                        _vm.$set(_vm.patient_form, "email", $$v)
                      },
                      expression: "patient_form.email"
                    }
                  }),
                  _vm._v(" "),
                  _vm.errors.email
                    ? _c("span", { staticClass: "error" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.errors.email) +
                            "\n            "
                        )
                      ])
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              false
                ? _c(
                    "div",
                    { staticClass: "col-md-6 patient-v2__item" },
                    [
                      _c("label", { staticClass: "patient-page__label" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("objects.patient.text.mail_delivery_setting")
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _c("checkbox-common", {
                        attrs: {
                          name: "is_receive_email",
                          "is-checked": _vm.patient_form.is_receive_email === 1,
                          label: _vm.$t("new_text.receive_sms")
                        },
                        model: {
                          value: _vm.patient_form.is_receive_email,
                          callback: function($$v) {
                            _vm.$set(_vm.patient_form, "is_receive_email", $$v)
                          },
                          expression: "patient_form.is_receive_email"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "patient-v2__row" }, [
            _c("div", { staticClass: "col-12 patient-v2__item" }, [
              _c("h2", { staticClass: "patient-v2__row__title" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("objects.patient.text.assignee_info")) +
                    "\n          "
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "patient-v2__row__group" }, [
              _c("div", { staticClass: "col-12" }, [
                _c("div", { staticClass: "position-wrapper" }, [
                  _vm.person_in_charges.length
                    ? _c(
                        "div",
                        { staticClass: "position" },
                        [
                          _vm._l(_vm.person_in_charges, function(item, index) {
                            return _c(
                              "div",
                              { key: index, staticClass: "position__item" },
                              [
                                _c("div", { staticClass: "position-left" }, [
                                  _c(
                                    "p",
                                    { staticClass: "position-left__text" },
                                    [
                                      _vm._v(
                                        "\n                      " +
                                          _vm._s(item.staff.position.name) +
                                          "\n                    "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("span", {
                                    staticClass: "position-left__space"
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "position-left__text position-left__text--name"
                                    },
                                    [
                                      _vm._v(
                                        "\n                      " +
                                          _vm._s(item.staff.name) +
                                          "\n                    "
                                      )
                                    ]
                                  )
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "position-right",
                                    on: {
                                      click: function($event) {
                                        return _vm.removePerson(item.staff_id)
                                      }
                                    }
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src:
                                          "/images/icon-close-circle-gray.svg"
                                      }
                                    })
                                  ]
                                )
                              ]
                            )
                          }),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass:
                                "btn btn-default position-btn person-charge__patient",
                              attrs: {
                                "data-target": "position",
                                type: "button"
                              },
                              on: { click: _vm.showPersonModal }
                            },
                            [
                              _c("img", {
                                staticClass: "position-btn__icon",
                                attrs: { src: "/images/icon-plus-add.svg" }
                              }),
                              _vm._v(" "),
                              _c("p", { staticClass: "position-btn__text" }, [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(
                                      _vm.$t("objects.patient.text.add_charge")
                                    ) +
                                    "\n                  "
                                )
                              ])
                            ]
                          )
                        ],
                        2
                      )
                    : _c("div", { staticClass: "position" }, [
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-default position-btn person-charge__patient",
                            attrs: {
                              "data-target": "position",
                              type: "button"
                            },
                            on: { click: _vm.showPersonModal }
                          },
                          [
                            _c("img", {
                              staticClass: "position-btn__icon",
                              attrs: { src: "/images/icon-plus-add.svg" }
                            }),
                            _vm._v(" "),
                            _c("p", { staticClass: "position-btn__text" }, [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    _vm.$t("objects.patient.text.add_charge")
                                  ) +
                                  "\n                  "
                              )
                            ])
                          ]
                        )
                      ])
                ])
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "patient-v2__row" }, [
            _c("div", { staticClass: "col-12 patient-v2__item" }, [
              _c("h2", { staticClass: "patient-v2__row__title" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("objects.patient.text.other_notes")) +
                    "\n          "
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "patient-v2__row__group" }, [
              _c("div", { staticClass: "col-12 patient-v2__item" }, [
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.patient_form.note,
                      expression: "patient_form.note"
                    }
                  ],
                  staticClass: "textarea-common",
                  attrs: {
                    name: "note",
                    placeholder: _vm.$t("new_text.placeholder.please_enter"),
                    cols: "2",
                    rows: "6"
                  },
                  domProps: { value: _vm.patient_form.note },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.patient_form, "note", $event.target.value)
                    }
                  }
                })
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("loading"),
      _vm._v(" "),
      _vm.is_show_modal_select_person
        ? _c("modal-select-person", {
            attrs: { "position-data": _vm.positionData },
            on: {
              "person-data": _vm.updateStaffData,
              close: function($event) {
                _vm.is_show_modal_select_person = false
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }