var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "upload-additional-file" },
    [
      _c("div", [
        _c(
          "h2",
          {
            staticClass:
              "upload-additional-file__title upload-additional-file__title--not-first"
          },
          [
            _c("span", [
              _vm._v(
                _vm._s(_vm.$t("objects.orders.text.file_upload_additional"))
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "upload-additional-file__title__help-link",
                on: {
                  click: function($event) {
                    return _vm.triggerClick(
                      _vm.trigger_click_type.MENU,
                      _vm.trigger_click_content.TAKE_PICTURE_HELP_LINK
                    )
                  },
                  mouseup: function($event) {
                    if ("button" in $event && $event.button !== 1) {
                      return null
                    }
                    return _vm.triggerClick(
                      _vm.trigger_click_type.MENU,
                      _vm.trigger_click_content.TAKE_PICTURE_HELP_LINK
                    )
                  }
                }
              },
              [
                _c(
                  "a",
                  {
                    attrs: {
                      href: _vm.take_picture_help_link,
                      target: "_blank"
                    }
                  },
                  [_c("img", { attrs: { src: "/images/icon-help.svg" } })]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    attrs: {
                      href: _vm.take_picture_help_link,
                      target: "_blank"
                    }
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t("objects.orders.text.file_upload_help_link")
                      ) + "\n        "
                    )
                  ]
                )
              ]
            )
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "upload-additional-file__notes" }, [
          _c(
            "p",
            {
              staticClass:
                "upload-additional-file__note upload-additional-file__note--red"
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$t("objects.orders.text.file_upload_additional_note_1")
                  ) +
                  "\n      "
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "p",
            {
              staticClass:
                "upload-additional-file__note upload-additional-file__note--red"
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$t("objects.orders.text.file_upload_additional_note_2")
                  ) +
                  "\n      "
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "p",
            {
              staticClass:
                "upload-additional-file__note upload-additional-file__note--red"
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$t("objects.orders.text.file_upload_additional_note_3")
                  ) +
                  "\n      "
              )
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "upload-additional-file__drag" }, [
          _c("input", {
            staticClass:
              "upload-additional-file__input upload-additional-file__input--hidden",
            attrs: {
              id: "upload-additional-file",
              type: "file",
              title: "",
              multiple: "",
              "data-key": "file_upload",
              accept: _vm.order_uploaded_file.join(", ")
            },
            on: {
              change: _vm.fileChange,
              click: function($event) {
                return _vm.clearValue($event)
              }
            }
          }),
          _vm._v(" "),
          _c("label", { staticClass: "upload-additional-file__label" }, [
            _c("span", { staticClass: "pc" }, [
              _vm._v(_vm._s(_vm.$t("objects.orders.text.drag_limit_title"))),
              _c("br")
            ]),
            _vm._v(" "),
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$t("objects.orders.text.drag_limit_size_with_stl", {
                    limit: _vm.uploading_limit,
                    limit_stl: _vm.uploading_limit_stl
                  })
                )
              )
            ])
          ]),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c(
            "label",
            {
              staticClass: "upload-additional-file__button",
              attrs: { for: "upload-additional-file" }
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("objects.orders.text.select_files")) +
                  "\n      "
              )
            ]
          )
        ])
      ]),
      _vm._v(" "),
      _vm.form_data.file_upload.length
        ? _c("div", { staticClass: "upload-additional-file__preview" }, [
            _c(
              "div",
              { staticClass: "upload-additional-file__preview__header" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "upload-additional-file__preview__header__title"
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t("objects.orders.text.file_uploaded_additional")
                      )
                    )
                  ]
                ),
                _vm._v(" "),
                _c("common-button", {
                  class: [
                    "button-v2--primary",
                    "button-v2--150",
                    "upload-additional-file__preview__header__button"
                  ],
                  on: { click: _vm.handleUploadFile },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "content",
                        fn: function() {
                          return [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("objects.orders.button.upload")) +
                                "\n        "
                            )
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    3376669736
                  )
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "upload-additional-file__files" },
              _vm._l(_vm.form_data.file_upload, function(item, key) {
                return _c(
                  "div",
                  { key: key, staticClass: "upload-additional-file__file" },
                  [
                    _c(
                      "div",
                      {
                        class:
                          "upload-additional-file__inner upload-additional-file__inner--uploaded upload-additional-file__inner--" +
                          _vm.getExtension(item.name),
                        on: {
                          click: function($event) {
                            return _vm.lightBoxPdf(item)
                          }
                        }
                      },
                      [
                        _vm.getExtension(item.name) ===
                          _vm.file_extension.PDF && _vm.is_rerender_pdf_file
                          ? _c("pdf", { attrs: { src: _vm.getUrlFile(item) } })
                          : _c("img", {
                              staticClass: "upload-additional-file__image",
                              attrs: { src: _vm.getUrlFile(item) }
                            })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "upload-additional-file__information" },
                      [
                        _c(
                          "h3",
                          { staticClass: "upload-additional-file__file--name" },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(item.name) +
                                "\n          "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "p",
                          {
                            staticClass:
                              "upload-additional-file__file--extension"
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.getDate(item.lastModified)) +
                                "\n          "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "upload-additional-file__remove",
                            on: {
                              click: function($event) {
                                return _vm.removeFile(item, key, "file_upload")
                              }
                            }
                          },
                          [_c("img", { attrs: { src: "/images/close-x.svg" } })]
                        )
                      ]
                    )
                  ]
                )
              }),
              0
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.is_show_modal
        ? _c("ModalPDF", {
            attrs: { url: _vm.pdf_url, extension: _vm.extension },
            on: { "close-modal": _vm.closeModal }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }