<template>
  <input
    v-model="value"
    class="input-default"
    @input="$emit('input', $event.target.value)"
    @keypress="$emit('keypress', $event)"
    @paste="$emit('paste', $event)"
    @click="$emit('click', $event)"
  >
</template>

<script>
  export default {
    props: {
      defaultValue: {
        type: [Number, String],
        default: ''
      }
    },
    emits: ['input', 'keypress', 'click'],
    data () {
      return {
        value: ''
      }
    },
    watch: {
      defaultValue (newVal) {
        this.value = newVal
      }
    },
    created () {
      this.value = this.defaultValue
    },
    methods: {
      set (value) {
        this.value = value
      }
    }
  }
</script>
